import { store } from "core/store/configureStore"
import { openModal } from "library/common/actions/modal"
import { ActionLogin, CoachMarkRecord } from "library/common/actions/user"
import { Modals } from "library/common/reducers/modalsReducer"
import { RENEW_SUBSCRIPTION_URL } from "library/utilities/constants"
import { fetch } from "../utilities/authFetch"
import authorizedFetch from "../utilities/fetch"

const show403ErrorModal = (e: any) => {
  if (RENEW_SUBSCRIPTION_URL && e.response.status === 403) {
    store.dispatch(openModal(Modals.NO_SUBSCRIPTION_MODAL))
  }

  return Promise.reject(e)
}

export const requestLogin = (data: ActionLogin) => {
  const action = data.isSSO
    ? fetch.post("/sso-login/", {
        sso_provider: data.email,
        token: data.password,
        redirect_uri: data.redirect_uri,
        direct_login_username: data.directLoginUsername,
      })
    : fetch.post("/login/", {
        username: data.email,
        password: data.password,
      })
  return action.catch(show403ErrorModal)
}
export const requestGetUserName = () =>
  authorizedFetch.get(`/user/me/`).catch(show403ErrorModal)
export const requestSetKnownHandlers = (data: { knownHandlers: string[] }) =>
  authorizedFetch.post(`/user/me/`, data).catch(show403ErrorModal)
export const requestSetWhatsNew = (data: { whatsNew: string }) =>
  authorizedFetch.post(`/user/me/`, data).catch(show403ErrorModal)

export const requestSetLastCoachMarks = (data: {
  lastCoachMarks: CoachMarkRecord
}) => authorizedFetch.post(`/user/me/`, data).catch(show403ErrorModal)

export const requestGenerateToken = () =>
  authorizedFetch.post(`/upload-token/`).catch((e) => {
    console.error(e)
  })

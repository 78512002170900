import { RootState } from "core/store/configureStore"
import {
  FEATURE_BONE_LOSS,
  FEATURE_CALCULUS,
  FEATURE_NERVUS,
  FEATURE_DRAWING_MODE,
  SHOW_INACTIVE_PBL_BUTTON,
  FEATURE_IMPACTED,
  SHOW_FULL_PBL_FORM,
  SHOW_DRAWING_MODE_IN_PERI,
  HIDE_PBL_CLASSIFICATION,
} from "library/utilities/constants"
import { Theme } from "../actions/user"
import { Kind } from "../types/serverDataTypes"
import { canHaveBoneLossPro } from "./utils/features"

export const getIsAuthenticated = (state: RootState) =>
  state.user.isAuthenticated
export const getEmail = (state: RootState) => state.user.email
export const getLoginFailed = (state: RootState) => state.user.loginFailed
export const getLoginFailedStatus = (state: RootState) => state.user.failStatus
export const getNewPasswordError = (state: RootState) =>
  state.user.newPasswordError
export const getOldPasswordError = (state: RootState) =>
  state.user.oldPasswordError
export const getIsLoadingResetPassword = (state: RootState) =>
  state.user.isLoadingResetPassword
export const getIsSuccessResetPassword = (state: RootState) =>
  state.user.isSuccessResetPassword
export const getMustResetPassword = (state: RootState) =>
  state.user.isMustResetPassword && !state.user.SSO
export const getLastCoachMarks = (state: RootState) => state.user.lastCoachMarks
export const getUserName = (state: RootState) => state.user.userName
export const getUserEmail = (state: RootState) => state.user.userEmail
export const getUserSSO = (state: RootState) => state.user.SSO
export const getServerError = (state: RootState) => state.user.serverError
export const getServerErrorMessage = (state: RootState) =>
  state.user.serverErrorMessage

export const getHandlerName = (state: RootState) => state.user.handlerName
export const getHandlerHash = (state: RootState) => state.user.handlerHash
export const getKnownHandlers = (state: RootState) => state.user.knownHandlers
export const getCariesProProfile = (state: RootState) => state.user.cariesPro
export const getBonelossProProfile = (state: RootState) =>
  FEATURE_BONE_LOSS ? state.user.bonelossPro : null
export const getShowBonelossPro = (state: RootState) =>
  FEATURE_BONE_LOSS &&
  state.user.bonelossPro !== null &&
  canHaveBoneLossPro(state.serverData.present.imageMeta.kind)
export const getShowBoneLossLite = (state: RootState) =>
  (SHOW_FULL_PBL_FORM && !HIDE_PBL_CLASSIFICATION
    ? state.user.boneLossLite
    : true) && canHaveBoneLossPro(state.serverData.present.imageMeta.kind)
export const getUploadsRemaining = (state: RootState) =>
  state.user.uploadsRemaining
export const getWhatsNew = (state: RootState) => state.user.whatsNew
export const getTheme = (state: RootState) => state.user.theme
export const getCalculus = (state: RootState) =>
  FEATURE_CALCULUS && state.user.calculus
// Only show true if it is activated on the backend and the user has permission for feature
export const getNervus = (state: RootState) =>
  FEATURE_NERVUS && state.user.nervus
export const getImpacted = (state: RootState) =>
  FEATURE_IMPACTED && state.user.impacted
export const getLicence = (state: RootState) => state.user.licence
export const getLicenceExpire = (state: RootState) => state.user.licenceExpire
export const getShowDrawingMode = (state: RootState) =>
  FEATURE_DRAWING_MODE &&
  (SHOW_DRAWING_MODE_IN_PERI ||
    state.serverData.present.imageMeta.kind !== Kind.Peri) &&
  state.user.showDrawingMode
export const getBoneLossOnly = (state: RootState) => state.user.boneLossOnly
export const getShowInactiveBoneLossButton = (state: RootState) =>
  SHOW_INACTIVE_PBL_BUTTON &&
  canHaveBoneLossPro(state.serverData.present.imageMeta.kind)

export const getModalities = (state: RootState) => state.user.modalities
export const getNumberingSystem = (state: RootState) =>
  state.user.numberingSystem
export const getShowModalityChooser = (state: RootState) =>
  state.user.modalities.length > 1 && state.user.theme !== Theme.carestream
export const getShowToothBasedPeri = (state: RootState) =>
  state.user.toothBasedPeri
export const getRole = (state: RootState) => state.user.role
export const getGeneratedToken = (state: RootState) => state.user.generatedToken

import { createAction } from "typesafe-actions"

import {
  imageTypes,
  PdfReport,
  PdfReportData,
  RotateData,
} from "library/common/types/imageTypes"

export const loadImage = createAction(imageTypes.LOAD_IMAGE)<string>()
export const loadImageSuccess = createAction(imageTypes.LOAD_IMAGE_SUCCESS)()
export const imageProcessingComplete = createAction(
  imageTypes.IMAGE_PROCESSING_COMPLETE
)()
export const loadAnnotations = createAction(imageTypes.LOAD_ANNOTATIONS)()
export const loadAnnotationsSuccess = createAction(
  imageTypes.LOAD_ANNOTATIONS_SUCCESS
)()
export const setInitialState = createAction(imageTypes.SET_INITIAL_STATE)()
export const rotateImage = createAction(imageTypes.ROTATE_IMAGE)<RotateData>()
export const setAsNotProcessed = createAction(imageTypes.SET_AS_NOTPROCESSED)()
export const showAnnotations = createAction(
  imageTypes.SHOW_ANNOTATIONS
)<boolean>()
export const changeRadiographType = createAction(
  imageTypes.CHANGE_RADIOGRAPH_TYPE
)<any>()
export const updateOpenDateMs = createAction(imageTypes.UPDATE_OPENDATEMS)()
export const loadPdfReport = createAction(
  imageTypes.LOAD_PDF_REPORT
)<PdfReport>()
export const loadPdfReportSuccess = createAction(
  imageTypes.LOAD_PDF_REPORT_SUCCESS
)<PdfReportData>()
export const openPdfReport = createAction(imageTypes.OPEN_PDF_REPORT)()
export const setShowImmediately = createAction(
  imageTypes.SET_SHOW_IMMEDIATELY
)<boolean>()
export const reanalyzeImage = createAction(imageTypes.REANALYZE_IMAGE)()
export const revertImage = createAction(imageTypes.REVERT_IMAGE)<number>()

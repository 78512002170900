import React from "react"

export const ArrowDropdownIcon = () => (
  <svg
    width="18"
    height="25"
    viewBox="0 0 18 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.13684 13.375L8.80602 17.125L12.4752 13.375H5.13684Z"
      fill="currentColor"
      fillOpacity="0.445"
    />
    <path
      d="M12.4752 10.875L8.80604 7.125L5.13686 10.875L12.4752 10.875Z"
      fill="currentColor"
      fillOpacity="0.445"
    />
  </svg>
)

import axios from "axios"
import mockMapping from "./mockMapping"

interface RequestConfig {
  method?: string
  [propName: string]: any
}
interface DefaultHeaders {
  [propName: string]: string
}

const defaultRequestConfig = { method: "get" } as RequestConfig

class FetchMock {
  fetch: any = null
  defaults = {
    headers: {
      common: {} as DefaultHeaders,
    },
  }

  mapping = mockMapping

  constructor() {
    this.fetch = axios.create({ baseURL: window.location.origin })
  }
  get(url: string, config: RequestConfig = defaultRequestConfig): Promise<any> {
    return this.request(url, null, { ...config, method: "get" })
  }
  head(
    url: string,
    config: RequestConfig = defaultRequestConfig
  ): Promise<any> {
    return this.request(url, null, { ...config, method: "head" })
  }
  delete(
    url: string,
    config: RequestConfig = defaultRequestConfig
  ): Promise<any> {
    return this.request(url, null, { ...config, method: "delete" })
  }
  options(
    url: string,
    config: RequestConfig = defaultRequestConfig
  ): Promise<any> {
    return this.request(url, null, { ...config, method: "options" })
  }
  post(
    url: string,
    data?: any,
    config: RequestConfig = defaultRequestConfig
  ): Promise<any> {
    return this.request(url, data || "", { ...config, method: "post" })
  }
  put(
    url: string,
    data: any,
    config: RequestConfig = defaultRequestConfig
  ): Promise<any> {
    return this.request(url, data, { ...config, method: "put" })
  }
  patch(
    url: string,
    data: any,
    config: RequestConfig = defaultRequestConfig
  ): Promise<any> {
    return this.request(url, data, { ...config, method: "patch" })
  }

  request(url: string, data: any, config: RequestConfig) {
    if (url.startsWith("/image/")) {
      const response = this.spoofUrl(url)
      if (response) return response
    }
    return new Promise((resolve) => {
      resolve(this.getResponse(url, data, config))
    })
  }

  getResponse(url: string, data: any, config: RequestConfig) {
    const method: string = config?.["method"] || "get"
    const response = this.mapping?.[`${method}_${url}`]

    if (!response) {
      console.error(`Missing data for the request ${url}`)
      return { data: {} }
    }
    return { data: response }
  }

  spoofUrl(url: string): Promise<any> | undefined {
    const matches = /\/([^\/]+)\/([^\/\.]+)[\/\.]/.exec(url)
    if (matches && matches?.length > 2) {
      const imageId = matches[2]
      try {
        const hashedPath = require(`resources/files/${imageId}.jpg`)
        return this.fetch.get(hashedPath, { responseType: "arraybuffer" })
      } catch (e) {
        console.error(
          `Unable to access ${`resources/files/${imageId}.jpg`} ${e.message}`
        )
        return new Promise((resolve) => {
          resolve({ data: "" })
        })
      }
    }
  }
}

export default FetchMock

import { IMeta } from "./serverDataTypes"

export enum patientTypes {
  SET_ACTIVE_PATIENT_IMAGES = "@@PATIENT/SET_ACTIVE_PATIENT_IMAGES",
  GET_PATIENTS_FROM_SERVER = "@@PATIENT/GET_PATIENTS_FROM_SERVER",
  SET_PATIENT_LIST = "@@PATIENT/SET_PATIENT_LIST",
  SET_ACTIVE_PATIENT_ID = "@@PATIENT/SET_ACTIVE_PATIENT_ID",
  DELETE_ACTIVE_PATIENT_XRAY = "@@PATIENT/DELETE_ACTIVE_PATIENT_XRAY",
  SET_INITIAL_STATE = "@@PATIENT/SET_INITIAL_STATE",
  SET_PAGINATION = "@@PATIENT/SET_PAGINATION",
  SET_FILTER_VALUES = "@@PATIENT/SET_FILTER_VALUES",
}

export interface Patient {
  id: string
  patientID: string
  patientName: string
  dateOfBirth: string
  imageDate: string
  modalities: Record<string, number>
  reportDate: string
  comments: string
  viewed: boolean
}

export interface ActivePatientImages {
  resultId: string | null
  report_submitted: string
  viewed: boolean
  generalComment: string
  addedComments: { comment: string; toothName: number }[]
  imageMetadata: IMeta
  imageDate: string
}

export interface PatientFileQuery {
  patientID?: string
  patientName?: string
}
export interface IPagination {
  page: number
  perPage: number
}

export interface FilterState {
  section: string
  selected: string[]
  match: (patient: Patient, selected: string[]) => boolean
}

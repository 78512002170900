import { put, takeLatest } from "redux-saga/effects"

import { WebSocketTypes } from "library/common/types/webSocketTypes"

import * as webSocketActions from "library/common/actions/webSocket"
import * as imageActions from "library/common/actions/image"

function* connectSaga({
  payload: imageId,
}: ReturnType<typeof webSocketActions.connect>) {
  yield put(imageActions.imageProcessingComplete())
}

export default function* entitiesSaga() {
  yield takeLatest(WebSocketTypes.CONNECT, connectSaga)
}

import { createAction } from "typesafe-actions"
import {
  ActivePatientImages,
  Patient,
  patientTypes,
  PatientFileQuery,
  IPagination,
  FilterState,
} from "../types/patientTypes"

export const getPatientsFromServer = createAction(
  patientTypes.GET_PATIENTS_FROM_SERVER
)()
export const setPatientList = createAction(patientTypes.SET_PATIENT_LIST)<
  Patient[]
>()
export const setActivePatientImages = createAction(
  patientTypes.SET_ACTIVE_PATIENT_IMAGES
)<ActivePatientImages[]>()
export const setActivePatientId = createAction(
  patientTypes.SET_ACTIVE_PATIENT_ID
)<PatientFileQuery>()
export const deleteActivePatientXray = createAction(
  patientTypes.DELETE_ACTIVE_PATIENT_XRAY
)<string>()
export const setInitialState = createAction(patientTypes.SET_INITIAL_STATE)()
export const setPagination = createAction(
  patientTypes.SET_PAGINATION
)<IPagination>()
export const setFilterValues = createAction(patientTypes.SET_FILTER_VALUES)<
  FilterState[]
>()

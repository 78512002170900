import { Theme } from "library/common/actions/user"
import { ReleaseType } from "library/common/types/serverDataTypes"
import {
  AnnotationName,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"

export const DEFAULT_THEME =
  (process.env.REACT_APP_DEFAULT_THEME as Theme) || Theme.dentalxrai

export const WHATS_NEW_VERSION = DEFAULT_THEME == Theme.dentalxrai ? "3" : "2"

export const DEFAULT_COACH_MARKS = {
  cariesPro: 0,
  boneLossPro: 0,
  calculusXrayImage: 0,
  calculusToothMap: 0,
  calculusEditView: 0,
  cariesOnOpg: 0,
  autoCariesPro: 0,
}

export const SUBTYPE_RGB = {
  bridges: {
    red: 110,
    green: 229,
    blue: 255,
  },
  crowns: {
    red: 14,
    green: 180,
    blue: 217,
  },
}

export const ANNOTATION_HEX_COLORS = {
  caries: "#FF2800",
  apical: "#FF8C00",
  calculus: "#ffec00",
  bridges: "#6EE5FF",
  crowns: "#12B3D9",
  fillings: "#355EF2",
  roots: "#8a80ff",
  annotate: "#FFFFFF",
  select: "#FFFFFF",
  none: "#BABDC4",
  nervus: "#B7F63A",
  impacted: "#ff00ed",
  implant: "#6fa6ff",
}

export const BONELOSS_COLORS = {
  low: "#d1d1d1",
  medium: "#ffba66",
  high: "#ff8080",
}

export const MONO_BONELOSS_COLOR = "#8a80ff"

// These subtypes cannot exist together with crowns or implants
const BASE_DISABLING = [
  AnnotationName.apical,
  AnnotationName.caries,
  RestorationSubtype.fillings,
  AnnotationName.impacted,
  AnnotationName.nervus,
  RestorationSubtype.roots,
]

export const DISABLING_CASES = {
  calculus: [RestorationSubtype.bridges],
  crowns: [RestorationSubtype.bridges],
  apical: [RestorationSubtype.implants, RestorationSubtype.bridges],
  caries: [RestorationSubtype.implants, RestorationSubtype.bridges],
  fillings: [RestorationSubtype.implants, RestorationSubtype.bridges],
  impacted: [RestorationSubtype.implants, RestorationSubtype.bridges],
  nervus: [RestorationSubtype.implants, RestorationSubtype.bridges],
  roots: [RestorationSubtype.implants, RestorationSubtype.bridges],
  implants: BASE_DISABLING.concat(RestorationSubtype.bridges),
  bridges: BASE_DISABLING.concat(
    AnnotationName.calculus,
    RestorationSubtype.crowns,
    RestorationSubtype.implants
  ),
}

export const CARIES_OPACITY = 0.75
export const TOOTH_MAP_POSITION_ASPECT_RATIO = 1.85

export const DEVICE_ID = process.env.REACT_APP_DEVICE_ID || "4260739410006"
export const TITLE = process.env.REACT_APP_TITLE

export const FEATURE_NERVUS = !!process.env.REACT_APP_FEATURE_NERVUS
export const FEATURE_NERVE_CANAL = !!process.env.REACT_APP_FEATURE_NERVE_CANAL
export const FEATURE_CALCULUS = !!process.env.REACT_APP_FEATURE_CALCULUS
export const FEATURE_BONE_LOSS = !!process.env.REACT_APP_FEATURE_BONE_LOSS
export const FEATURE_DRAWING_MODE = !!process.env.REACT_APP_FEATURE_DRAWING_MODE
export const SHOW_INACTIVE_PBL_BUTTON =
  !!process.env.REACT_APP_SHOW_INACTIVE_PBL_BUTTON
export const REACT_APP_REMEMBER_HSM = !!process.env.REACT_APP_REMEMBER_HSM
export const REACT_APP_REVERT_VERSION = !!process.env.REACT_APP_REVERT_VERSION

export const OAUTH_PROVIDER_NAME = process.env.REACT_APP_OAUTH_PROVIDER_NAME
export const OAUTH_BASE_URI = process.env.REACT_APP_OAUTH_BASE_URI

export const SHOW_PDF_VERSION = !!process.env.REACT_APP_SHOW_PDF_VERSION
export const SHOW_NEW_PBL = !!process.env.REACT_APP_SHOW_NEW_PBL
export const SHOW_PERI = !!process.env.REACT_APP_SHOW_PERI
export const FEATURE_IMPACTED = !!process.env.REACT_APP_FEATURE_IMPACTED
export const SHOW_PBL_LANDMARKS = !!process.env.REACT_APP_SHOW_PBL_LANDMARKS
export const HIDE_PBL_CLASSIFICATION =
  !!process.env.REACT_APP_HIDE_PBL_CLASSIFICATION
export const SHOW_FULL_PBL_FORM = !!process.env.REACT_APP_SHOW_FULL_PBL_FORM
export const COPY_TO_CLIPBOARD = !!process.env.REACT_APP_COPY_TO_CLIPBOARD
export const SUPPORTED_LANGUAGES =
  process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",") || ["en"]
export const MANUAL_LANGUAGES = process.env.REACT_APP_MANUAL_LANGUAGES?.split(
  ","
) || ["en"]
export const DETECT_BROWSER_LANGUAGE =
  !!process.env.REACT_APP_DETECT_BROWSER_LANGUAGE
export const USER_MANUAL_VERSION = process.env.REACT_APP_USER_MANUAL_VERSION
export const SHOW_SSO = !!process.env.REACT_APP_SHOW_SSO
export const SHOW_COACH_MARKS = !!process.env.REACT_APP_SHOW_COACH_MARKS
export const RENEW_SUBSCRIPTION_URL =
  process.env.REACT_APP_RENEW_SUBSCRIPTION_URL
export const SHOW_THEME_TEST_BUTTONS =
  !!process.env.REACT_APP_SHOW_THEME_TEST_BUTTONS
export const MIRTH_INSTALLER = process.env.REACT_APP_MIRTH_INSTALLER
export const SHOW_WHATS_NEW = !!process.env.REACT_APP_SHOW_WHATS_NEW
export const SHOW_CARIES_PRO_FOR_PERI =
  !!process.env.REACT_APP_SHOW_CARIES_PRO_FOR_PERI
export const SHOW_PERIO_PRO_FOR_PERI =
  !!process.env.REACT_APP_SHOW_PERIO_PRO_FOR_PERI
export const SHOW_DRAWING_MODE_IN_PERI =
  !!process.env.REACT_APP_SHOW_DRAWING_MODE_IN_PERI
export const SHOW_HSM_IN_PERI = !!process.env.REACT_APP_SHOW_HSM_IN_PERI
export const PRODUCT_INFO_TO_SHOW =
  process.env.REACT_APP_PRODUCT_INFO_TO_SHOW || ReleaseType.all
export const INSTALLER_BY_DEFAULT = process.env.REACT_APP_INSTALLER_BY_DEFAULT

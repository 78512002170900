import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/patient"
import {
  ActivePatientImages,
  Patient,
  IPagination,
  FilterState,
} from "../types/patientTypes"
import { filterSections } from "pages/Patients/PatientList/filterSections"

type PatientState = Readonly<{
  patientList: Patient[]
  activePatientImages: ActivePatientImages[] | null
  pagination: IPagination
  filterValues: FilterState[]
}>
export const initialFilters = filterSections.map((s) => ({
  section: s.heading.value,
  selected: [],
  match: s.match,
}))

const initialState: PatientState = {
  patientList: [],
  activePatientImages: null,
  pagination: { page: 1, perPage: 50 },
  filterValues: initialFilters,
}

type PatientActions = ActionType<typeof actions>

export default (state = initialState, action: PatientActions): PatientState => {
  switch (action.type) {
    case getType(actions.setInitialState): {
      return { ...initialState }
    }
    case getType(actions.setPatientList): {
      return {
        ...state,
        patientList: action.payload,
      }
    }
    case getType(actions.setActivePatientImages): {
      return {
        ...state,
        activePatientImages: action.payload,
      }
    }
    case getType(actions.deleteActivePatientXray): {
      return {
        ...state,
        activePatientImages:
          state.activePatientImages?.filter(
            (a) => a.resultId !== action.payload
          ) || [],
      }
    }
    case getType(actions.setPagination): {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    }
    case getType(actions.setFilterValues): {
      return {
        ...state,
        filterValues: action.payload,
      }
    }
    default:
      return state
  }
}

export enum adjustmentTypes {
  ADJUST_ANNOTATIONS = "@@ADJUSTMENTS/ADJUST_ANNOTATIONS",
  ADJUST_ANNOTATIONS_SUCCESS = "@@ADJUSTMENTS/ADJUST_ANNOTATIONS_SUCCESS",
  MOVE_ANNOTATION = "@@ADJUSTMENTS/MOVE_ANNOTATION",
  MOVE_ANNOTATION_SUCCESS = "@@ADJUSTMENTS/MOVE_ANNOTATION_SUCCESS",
  MOVE_ANNOTATION_TO = "@@ADJUSTMENTS/MOVE_ANNOTATION_TO",
  MOVE_ANNOTATION_TO_SUCCESS = "@@ADJUSTMENTS/MOVE_ANNOTATION_TO_SUCCESS",
  TOGGLE_ANNOTATION = "@@ADJUSTMENTS/TOGGLE_ANNOTATION",
  TOGGLE_ANNOTATION_ON_TOOTH = "@@ADJUSTMENTS/TOGGLE_ANNOTATION_ON_TOOTH",
  TOGGLE_PERI_ANNOTATION = "@@ADJUSTMENTS/TOGGLE_PERI_ANNOTATION",
  TOGGLE_ANNOTATION_SUCCESS = "@@ADJUSTMENTS/TOGGLE_ANNOTATION_SUCCESS",
  START_EXPANDING = "@@ADJUSTMENTS/START_EXPANDING",
  START_EXPANDING_SUCCESS = "@@ADJUSTMENTS/START_EXPANDING_SUCCESS",
  EXPAND_SHIFTING_TO = "@@ADJUSTMENTS/EXPAND_SHIFTING_TO",
  EXPAND_SHIFTING_TO_SUCCESS = "@@ADJUSTMENTS/EXPAND_SHIFTING_TO_SUCCESS",
  MOVE_SHIFTING_STACK_TO = "@@ADJUSTMENTS/MOVE_SHIFTING_STACK_TO",
  SET_NEXT_ACTIVE_STACK = "@@ADJUSTMENTS/SET_NEXT_ACTIVE_STACK",
  SET_NEXT_ACTIVE_STACK_SUCCESS = "@@ADJUSTMENTS/SET_NEXT_ACTIVE_STACK_SUCCESS",
  SET_INITIAL_STATE = "@@ADJUSTMENTS/SET_INITIAL_STATE",
  TOGGLE_TEETH_ARE_SHIFTING = "@@ADJUSTMENTS/TOGGLE_TEETH_ARE_SHIFTING",
}

export enum AnnotationName {
  caries = "caries",
  apical = "apical",
  restorations = "restorations",
  restoration = "restoration", // Old type, kept for legacy support.
  calculus = "calculus",
  nervus = "nervus",
  impacted = "impacted",
  annotate = "annotate",
  nerveCanal = "nerveCanal",
}

export enum RestorationSubtype {
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
  implants = "implants",
  roots = "roots",
}

export enum AllAnnotations {
  none = "",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  nervus = "nervus",
  impacted = "impacted",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
  implants = "implants",
  roots = "roots",
}

export enum CariesDepth {
  enamel = "e",
  dentin = "d",
  unknown = "",
}

export enum AllAnnotationsLegend {
  none = "",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  nervus = "nervus",
  impacted = "impacted",
  bridge = "bridge",
  crown = "crown",
  filling = "filling",
  implant = "implant",
  root = "root",
  nervusLong = "nervusLong",
}

export type AnnotationOnTooth = Readonly<{
  toothName: number
  type: AnnotationName
  subtype?: RestorationSubtype

  // Caries Pro:
  location?: string
  depth?: CariesDepth
  id?: number // we need to distinguish between the user additions
  mask?: string | undefined
  ids?: number[] | null

  rejectedIds?: number[] // referencing some AI detections that are rejected by this drawing
  isReplacedSubtype?: boolean // Only for when we replace a crown/bridge with a crown/bridge
}>

export interface ShiftingSelection {
  start: number
  to: number
}

export type PeriAnnotation = Readonly<{
  annotationId: number
  isChecked: boolean
}>

export interface ShiftingBounds extends ShiftingSelection {
  upper: boolean
}

export type BonelossAnnotation = Readonly<{
  d?: number // distal
  m?: number // mesial
  toothName: number
  landmarks: Landmark
}>

export type BoneLossAdjustment = Readonly<{
  d?: number[] // Always in sequence (CEJ, PBL, apex)
  m?: number[]
}>

export interface BonelossChange {
  d?: number // distal
  m?: number // mesial
  toothName: number
  isChecked: boolean
  landmarks?: Landmark
  aiLandmarks?: Landmark
  adjusted: BoneLossAdjustment
}

export interface Landmark {
  d?: number[][]
  m?: number[][]
}

export type ActivePblLandmark = Readonly<{
  landmarkPoints: number[][]
  aiLandmarkPoints: number[][]
  adjusted: BoneLossAdjustment
}>

export type PblLandmarkStart = Readonly<{
  mouseX: number
  mouseY: number

  landmarkIndex: number // 0 - 5 index into the landmarkPoints
  startValue: number
  syncedApexStartValue?: number // for synced apex teeth: the other start value
}>

export enum ScrollIconPosition {
  top,
  bottom,
  both,
}

export type ScrollIconData = Readonly<{
  clientHeight: number
  scrollHeight: number
  scrollTop: number
  offsetWidth: number
}>

export type TeethPosition = Readonly<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>

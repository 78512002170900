import { takeEvery, put, select } from "redux-saga/effects"

import * as serverDataActions from "library/common/actions/serverData"
import * as serverDataSelectors from "library/common/selectors/serverData"

import {
  BoneLossFormFull,
  ServerDataTypes,
} from "library/common/types/serverDataTypes"
import { AnnotationOnTooth } from "../types/adjustmentTypes"
import { UserChange } from "../types/dataStructureTypes"
import * as withHistoryActions from "../actions/withHistory"
import { setDrawingAction, toggleActiveDrawingMode } from "../actions/drawing"
import { isDrawingModeActive } from "../selectors/drawing"
import { DrawingAction } from "../types/drawing"
import { getShowDrawingMode } from "../selectors/user"
import { getCariesPro } from "../selectors/image"

function* changeAnnotationSaga({
  payload: { id, location, depth },
}: ReturnType<typeof serverDataActions.changeAnnotation>) {
  yield put(withHistoryActions.rememberState())
  const additions: AnnotationOnTooth[] = yield select(
    serverDataSelectors.getAllAdditions
  )
  const addition = additions.find((a) => a.id === id)
  if (addition) {
    // case 1: change the user addition
    yield put(
      serverDataActions.changeUserAddition({
        ...addition,
        location: location ?? addition.location,
        depth: depth ?? addition.depth,
      })
    )
  } else {
    const changes: UserChange[] = yield select(
      serverDataSelectors.getAllUserChanges
    )
    const change = changes.find(
      (c) => c.annotationId === id && c.action === "changed"
    )
    if (change) {
      // case 2: edit existing change
      yield put(
        serverDataActions.editUserChange({
          oldChange: change,
          newChange: {
            ...change,
            location: location ?? change.location,
            depth: depth ?? change.depth,
          },
        })
      )
    } else {
      // case 3: create new change
      yield put(
        serverDataActions.addUserChanges([
          { annotationId: id, action: "changed", location, depth },
        ])
      )
    }
  }
}

function* toggleAnnotationSaga({
  payload: id,
}: ReturnType<typeof serverDataActions.toggleAnnotation>) {
  yield put(withHistoryActions.rememberState())
  const disabledAnnotations: number[] = yield select(
    serverDataSelectors.getDisabledAnnotations
  )
  if (disabledAnnotations.includes(id)) {
    yield put(
      serverDataActions.deleteUserChange({
        id,
        deleteNonHSM: true,
      })
    )
  } else {
    yield put(
      serverDataActions.addUserChanges([
        { annotationId: id, action: "rejected" },
      ])
    )
  }
}

function* resetHistory() {
  yield put(withHistoryActions.setInitialState())
}

function* deleteUserAdditionByIdSaga({
  payload,
}: ReturnType<typeof serverDataActions.deleteUserAdditionById>) {
  yield put(withHistoryActions.rememberState())
  yield put(serverDataActions.deleteUserAdditionByIdSuccess(payload))

  const cariesPro: string = yield select(getCariesPro)
  const showDrawingMode: boolean = yield select(getShowDrawingMode)

  if (!cariesPro || !showDrawingMode) return

  const additions: AnnotationOnTooth[] = yield select(
    serverDataSelectors.getAllAdditions
  )
  const lastAddedDetection = additions[additions.length - 1]
  const drawingModeActive: boolean = yield select(isDrawingModeActive)

  /*
  When deleting an added caries pro addition, make previous addition the active drawing
  else if it is the last detection, toggle drawing mode off
  */
  if (!lastAddedDetection) {
    if (drawingModeActive) yield put(toggleActiveDrawingMode())
    return
  }
}

function* addCariesAdditionsSaga({
  payload,
}: ReturnType<typeof serverDataActions.addCariesAdditions>) {
  yield put(withHistoryActions.rememberState())
  yield put(serverDataActions.addUserAdditions(payload))
  yield put(setDrawingAction(DrawingAction.caries))
}

function* forceBonelossComplexitySaga() {
  const boneLossForm: BoneLossFormFull = yield select(
    serverDataSelectors.getBoneLossFormValues
  )

  if (
    (!boneLossForm.showComplex && boneLossForm.complications === "complex") ||
    (!boneLossForm.showSt6 && boneLossForm.complications === "ST>=6")
  ) {
    yield put(serverDataActions.setComplications("-"))
  }
}

export default function* serverDataSaga() {
  yield takeEvery(ServerDataTypes.CHANGE_ANNOTATION, changeAnnotationSaga)
  yield takeEvery(ServerDataTypes.TOGGLE_ANNOTATION, toggleAnnotationSaga)
  yield takeEvery(ServerDataTypes.SET_INITIAL_STATE, resetHistory)
  yield takeEvery(
    ServerDataTypes.DELETE_USER_ADDITION_BY_ID,
    deleteUserAdditionByIdSaga
  )
  yield takeEvery(ServerDataTypes.ADD_CARIES_ADDITIONS, addCariesAdditionsSaga)
  yield takeEvery(
    [ServerDataTypes.SET_TEETH_LOSS, ServerDataTypes.SET_BONE_LOSS_CATEGORY],
    forceBonelossComplexitySaga
  )
}

import { createAction } from "typesafe-actions"
import { SaveComplete, SavingType, SavingTypes } from "../types/savingTypes"

export const requestSendChanges = createAction(
  SavingTypes.REQUEST_SEND_CHANGES
)<SavingType>()
export const requestSendChangesComplete = createAction(
  SavingTypes.REQUEST_SEND_CHANGES_COMPLETE
)<SaveComplete>()
export const setDataIsChanged = createAction(
  SavingTypes.SET_DATA_IS_CHANGED
)<boolean>()
export const requestSendEvent = createAction(
  SavingTypes.REQUEST_SEND_EVENT
)<string>()

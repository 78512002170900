import localStorage from "library/utilities/localStorage"
import i18n from "i18next"
import { SUPPORTED_LANGUAGES } from "library/utilities/constants"
import { Theme } from "library/common/actions/user"

export const supportedLanguages = SUPPORTED_LANGUAGES

export const setLanguage = (
  language: string | null,
  setLanguage: (lang: string) => void,
  theme: string
) => {
  const saveLang = (lang: string) => {
    localStorage.setItem("locale", lang)
    void i18n.changeLanguage(lang)
    setLanguage(lang)
  }

  // Ensure that we support language codes with regions e.g. en-US or en-GB
  const parsedLang = language && language.slice(0, 2)
  const supported = parsedLang && supportedLanguages.includes(parsedLang)
  if (supported) {
    // Set the language if it's supported
    saveLang(parsedLang)
  } else if (theme === Theme.carestream && language) {
    // for carestream we just set it to English when we receive an unknown / unsupported language
    saveLang("en")
  }
}

import { call, put, takeLatest } from "redux-saga/effects"

import {
  setAllXRays,
  setXRaysIsLoading,
  deleteXraySuccess,
} from "library/common/actions/xrays"
import { XraysTypes } from "library/common/types/xraysTypes"
import {
  requestGetAllXRays,
  requestDeleteXRay,
} from "library/services/xraysApi"
import { Kind } from "../types/serverDataTypes"
import { deleteActivePatientXray } from "../actions/patient"

interface IXRay {
  created: string
  report_submitted: string
  id: string
  imageMetadata: {
    kind: Kind
    fileName: string
    imageFormatOrig: string
    imageDate?: string
    patientID?: string
    dateOfBirth?: string
    patientName?: string
    isImageHorizontallyFlipped?: boolean
    angleImageRotation?: number
  }
}

interface IDeleteXray {
  payload: string
  type: string
}

function* getXRays() {
  try {
    yield put(setXRaysIsLoading(true))
    const { data } = yield call(requestGetAllXRays)
    const payload = data.map((item: IXRay) => ({
      id: item.id || "",
      kind: !item.imageMetadata.kind
        ? Kind.Unknown
        : Object.values(Kind).includes(item.imageMetadata.kind)
        ? item.imageMetadata.kind
        : Kind.Other,
      patientName: item.imageMetadata.patientName || "",
      patientId: item.imageMetadata.patientID || "",
      dateOfBirth: item.imageMetadata.dateOfBirth || "",
      imageDate: item.imageMetadata.imageDate || "",
      isImageHorizontallyFlipped:
        item.imageMetadata.isImageHorizontallyFlipped || false,
      rotation: item.imageMetadata.angleImageRotation || 0,
      uploadDate: item.created || "",
      reportDate: item.report_submitted || "",
    }))
    yield put(setAllXRays(payload))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(setXRaysIsLoading(false))
  }
}

function* deleteXray(id: IDeleteXray) {
  try {
    yield call(requestDeleteXRay, id.payload)
    yield put(deleteXraySuccess(id.payload))
    yield put(deleteActivePatientXray(id.payload))
  } catch (error) {
    console.log(error)
  }
}

export default function* watchXRays() {
  yield takeLatest(XraysTypes.GET_ALL_XRAYS, getXRays)
  yield takeLatest(XraysTypes.DELETE_XRAY_START, deleteXray)
}

import { call, put, takeLatest } from "redux-saga/effects"
import * as patientActions from "library/common/actions/patient"

import {
  requestGetActivePatient,
  requestGetAllPatients,
} from "library/services/patientApi"
import {
  ActivePatientImages,
  Patient,
  patientTypes,
} from "../types/patientTypes"
import { setPatientList } from "../actions/patient"
import { INSTALLER_BY_DEFAULT, SHOW_SSO } from "library/utilities/constants"
import { openModal } from "../actions/modal"
import { Modals } from "../reducers/modalsReducer"
import { Kind } from "../types/serverDataTypes"
import { transformDate } from "library/utilities/parseDate"

function* getPatientSaga() {
  try {
    const { data } = yield call(requestGetAllPatients)

    if (SHOW_SSO && data.length === 0 && INSTALLER_BY_DEFAULT) {
      yield put(openModal(Modals.NEW_USER_ONBOARDING_MODAL))
    }

    /*
   For the table list we require a field, "id" of type string.
   When the value is absent, use "".
  */
    const restructuredData = data.map((d: Patient) => {
      return {
        ...d,
        id: d.patientID || d.patientName || "",
        imageDate: transformDate(d.imageDate),
      }
    })

    yield put(setPatientList(restructuredData))
  } catch (error) {
    console.error("error loading patients", error)
  }
}

function* setActivePatientIdSaga({
  payload,
}: ReturnType<typeof patientActions.setActivePatientId>) {
  try {
    const { data }: { data: ActivePatientImages[] } = yield call(
      requestGetActivePatient,
      payload
    )

    yield put(
      patientActions.setActivePatientImages(
        data.map((d) => {
          return {
            ...d,
            imageDate: transformDate(
              d.imageDate || d.imageMetadata.analysisDate
            )!, // fail-safe in case the imageDate is empty
            generalComment: (d.generalComment || "").trim(), // trim whitespace, ensure not-null
            addedComments: (d.addedComments || []).map((c) => ({
              ...c,
              comment: (c.comment || "").trim(),
            })),
            imageMetadata: {
              ...d.imageMetadata,
              kind: d.imageMetadata.kind || Kind.Unknown, // Backend returns no kind when it is unknown
            },
          }
        })
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export default function* patientSaga() {
  yield takeLatest(patientTypes.GET_PATIENTS_FROM_SERVER, getPatientSaga)
  yield takeLatest(patientTypes.SET_ACTIVE_PATIENT_ID, setActivePatientIdSaga)
}

import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import userReducer from "library/common/reducers/userReducer"
import uploadReducer from "library/common/reducers/uploadReducer"
import xraysReducer from "library/common/reducers/xraysReducer"
import teethReducer from "library/common/reducers/teethReducer"
import filtersReducer from "library/common/reducers/filtersReducer"
import imageControlsReducer from "library/common/reducers/imageControlsReducer"
import modalsReducer from "library/common/reducers/modalsReducer"
import entitiesReducer from "library/common/reducers/entitiesReducer"
import imageReducer from "library/common/reducers/imageReducer"
import adjustmentReducer from "library/common/reducers/adjustmentsReducer"
import i18nReducer from "./i18n/reducer"
import serverDataReducer from "library/common/reducers/serverDataReducer"
import savingReducer from "library/common/reducers/savingReducer"
import legendReducer from "library/common/reducers/legendReducer"
import drawingReducer from "library/common/reducers/drawingReducer"
import { History } from "history"
import patientReducer from "library/common/reducers/patientReducer"

const createCoreReducer = (history: History) =>
  combineReducers({
    entities: entitiesReducer, // Contains data from server which is NOT changeable by the user.
    serverData: serverDataReducer, // Contains data from the server which is changeable by the user.
    router: connectRouter(history),
    image: imageReducer,
    user: userReducer,
    upload: uploadReducer,
    xrays: xraysReducer,
    teeth: teethReducer,
    filters: filtersReducer,
    imageControls: imageControlsReducer,
    adjustments: adjustmentReducer,
    modals: modalsReducer,
    i18n: i18nReducer,
    saving: savingReducer,
    legend: legendReducer,
    drawing: drawingReducer,
    patients: patientReducer,
  })

export default createCoreReducer

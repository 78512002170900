import fetch from "library/utilities/fetch"
import { REACT_APP_REVERT_VERSION } from "library/utilities/constants"
import { Kind } from "library/common/types/serverDataTypes"

export interface ReanalyzeRequest {
  id: string
  kind?: Kind
  rotate?: number
  isFlipped?: boolean
}

export const requestImageAnalysis = (id: string) =>
  fetch.get(`/result/${id}/?client=frontend`, {
    params: {
      showHistory: REACT_APP_REVERT_VERSION,
    },
  })

export const requestReanalyze = (data: ReanalyzeRequest) =>
  fetch.post(`/reanalyze/`, { ...data, ["frontend-upload"]: true })

export const requestPdfReport = (
  id: string,
  lang: string,
  theme: string,
  showVersion: boolean
) =>
  fetch.get(
    `/report-pdf/${id}.json?topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm&clipboard=1&detections=0.5`,
    { params: { lang, theme, showVersion } }
  )

export const requestBoneLossPdfReport = (
  id: string,
  lang: string,
  theme: string,
  showVersion: boolean
) =>
  fetch.get(
    `/report-pdf/${id}.json?topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm&reports=bone-loss&boneLossDetections=0.5&clipboard=1`,
    { params: { lang, theme, showVersion } }
  )

export const revertImage = (resultId: string, id: number) =>
  fetch.post("/revert/", { result_id: resultId, id })

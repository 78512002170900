import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { history, store } from "core/store/configureStore"
import configureI18n from "core/i18n/configureI18n"

import Routes from "core/Routes"

import "resources/styles/index.scss"
import { ThemeProvider } from "components/atoms/Baseline/Baseline.context"
import { FullScreenNotification } from "components/atoms/FullScreenNotification"
import { Baseline } from "components/atoms/Baseline"

async function render() {
  await configureI18n()

  createRoot(document.getElementById("root")!).render(
    <ThemeProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />

          <Baseline>
            <FullScreenNotification />
          </Baseline>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  )
}

void render()
